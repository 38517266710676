<template>
  <div class="nourished_section header-large bg-grey" id="nourished_section">
    <section id="nourished">
    <section class="section-height">
    </section>
    <!-- <section class="section-height tickerTapeWrap"  v-b-modal="'nourishModal'">
      <b-container fluid class="">
        <b-row class="">
          <b-col class="tickerTape">
            <div class="freight-big-pro-medium textBig">GRAND OPENING FALL FAIR</div>
            <div class="line bg-blue"></div>
            <div class="acumin-pro-wide-medium text">SEPTEMBER & OCTOBER</div>
            <div class="line bg-blue"></div>
            <div class="acumin-pro-wide-medium text">CLICK TO LEARN MORE</div>
          </b-col>
        </b-row>
      </b-container>
    </section> -->
    <b-container fluid class="p-0">
      <b-row class="pb-5">
        <b-col cols="11" class="southbay-img-wrapper relative">
          <div class="overflow-hidden">
            <div class="video-container">
              <video ref="mainVideo" controls @play="onPlay" @pause="onPause">
                <source :src="videoSrc" type="video/mp4">
                Your browser does not support the video tag.
              </video>
              <video class="preview-video" ref="previewVideo" autoplay loop muted>
                <source :src="previewVideoSrc" type="video/mp4">
                Your browser does not support the video tag.
              </video>
              <div class="play-button" v-show="showPlayButton" @click="playVideo">
                <img
                    src="/img/play-btn.png"
                    class="img-fluid w-100 h-100"
                    alt="Play Button"
                  />
              </div>
              <div class="pause-button" v-show="showPauseButton" @click="pauseVideo">&#10074;&#10074;</div>
            </div>
          </div>

          <object
            data="/img/BLUE_SEAL.svg"
            type="image/svg+xml"
            class="seal"
          ></object>
        </b-col>
      </b-row>
    </b-container>

    <div class="container desktop-version">
      <b-row class="text-container text-blue pt-4">
        <b-col>
          <div
            class="nature-container large-text overflow-hidden position-relative freight-big-pro-book"
            style="z-index: 10"
          >
            NOURISHED <br />
            BY NATURE,
          </div>
        </b-col>
        <b-col class="d-flex justify-content-end">
          <div class="sub-text acumin-pro-wide-light">
            Enjoy a serene lifestyle in the perfect neighbourhood setting —
            surrounded by nature, and connected to all the finest urban
            amenities.
          </div>
        </b-col>
      </b-row>
      <b-row class="text-container text-blue">
        <b-col>
          <div
            class="large-text overflow-hidden position-relative freight-big-pro-book"
            style="z-index: 10"
          >
            CONNECTED <br />
            TO CONVENIENCE
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="container mobile-version">
      <b-row class="text-container text-blue pt-4">
        <b-col class="">
          <div
            class="large-text overflow-hidden position-relative freight-big-pro-book"
            style="z-index: 10"
          >
            NOURISHED <br />
            BY NATURE, CONNECTED <br />
            TO CONVENIENCE
          </div>
        </b-col>
        <b-col>
          <div class="sub-text acumin-pro-wide-light pt-4">
            Enjoy a serene lifestyle in the perfect neighbourhood setting —
            surrounded by nature, and connected to all the finest urban
            amenities.
          </div>
        </b-col>
      </b-row>
    </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    videoSrc: {
      type: String,
      required: true
    },
    previewVideoSrc: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showPlayButton: true,
      showPauseButton: false
    };
  },
  methods: {
    // showButtons() {
    //   this.showPlayButton = true;
    //   this.showPauseButton = false;
    // },
    // hideButtons() {
    //   this.showPlayButton = false;
    //   this.showPauseButton = false;
    // },
    playVideo() {
      this.$refs.mainVideo.play();
      this.$refs.previewVideo.style.display = 'none';
      this.showPlayButton = false;
      this.showPauseButton = true;
    },
    pauseVideo() {
      this.$refs.mainVideo.pause();
      this.$refs.previewVideo.style.display = 'block';
      this.showPlayButton = true;
      this.showPauseButton = false;
    },
    onPlay() {
      this.$refs.previewVideo.style.display = 'none';
      this.showPlayButton = false;
    },
    onPause() {
      this.$refs.previewVideo.style.display = 'block';
      this.showPlayButton = true;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";
.header-large {
  padding: 0px 0px 100px 0px;
  position: relative;
  .section-height {
    height: 100px;
    @media screen and (max-width: 500px) {
      .section-height {
        height: 50px;
      }
    }
  }

  .tickerTapeWrap{
    background-color: $m-yellow;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .tickerTape{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .textBig{
        // width:22%;
        display: inline-block;
        text-align: center;
        font-size: 1.35rem;
        font-weight: bold;
      }
      .text{
        // width:22%;
        display: inline-block;
        text-align: center;
        font-size: 1.15rem;
      }
      .line{
        width:15%;
        height: 1px;
      }
    }
  }

  .nature-container {
    @media screen and (min-width: 1500px) {
        min-width: 650px;
      }
  }
  .southbay-img-wrapper {
    position: relative;

  .video-container {
  position: relative;
  // width: 400px;
  // height: 300px;
  overflow: hidden;
}

video {
  width: 100%;
  height: 100%;
}

.preview-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.play-button,
.pause-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  cursor: pointer;
}

.play-button {
  font-size: 48px;
  color: white;
}

.pause-button {
  font-size: 48px;
  color: white;
  display: none;
}

.play-button:hover + .pause-button {
  display: block;
}
.video-container:hover .pause-button {
  display: block;
}
    .seal {
      max-width: 140px;
      position: absolute;
      right: -4.6rem;
      bottom: -3.5rem;
      z-index: 99999;
      @media screen and (min-width: 1500px) {
        max-width: 160px;
        right: -6.3rem;
        bottom: -4.7rem;
      }
      @media screen and (max-width: 500px) {
        // top: 20rem;
        right: -1rem;
        max-width: 80px;
      }
    }
  }
  .mobile-version {
    display: none;
    @media screen and (max-width: 780px) {
      display: block;
    }
  }
  .desktop-version {
    @media screen and (max-width: 780px) {
      display: none;
    }
  }
  .sub-text {
    @media screen and (min-width: 2500px) {
      margin-left: 100px;
    }
  }
}
</style>
<style lang="scss">
#nourishModal{
  .modal-content{
    background-color: #eae8e4;
  }
  .modal-body{
    padding:0;
  }
}
</style>